import React from "react";
import { Link } from "react-router-dom";
import { Flex } from "@100mslive/roomkit-react";

const EventHeaderTop = () => {
  return (
    <Flex className="events6ix-layout-header-top">
      <div className="events6ix-layout-header-top-text">
        Looking to register for an event?
      </div>
      <Link to="/explore" className="events6ix-layout-header-top-link">
        Explore Events
      </Link>
    </Flex>
  );
};

export default EventHeaderTop;
